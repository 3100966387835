import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

const UserPages = lazy(() => import("../../Pages/UserPages"));
const Dashboards = lazy(() => import("../../Pages/Dashboards"));
const Settings = lazy(() => import("../../Pages/Settings"));
const Welcome = lazy(() => import("../../Pages/Welcome"));

class AppMain extends React.Component {
  render() {
    return (
      <Fragment>
        {/* Pages */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
                <h6 className="mt-3">
                  Cargando...
                  <small>Espere unos segundos..</small>
                </h6>
              </div>
            </div>
          }
        >
          <Route path="/pages" component={UserPages} />
        </Suspense>

        {/* Dashboards */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
                <h6 className="mt-3">
                  Cargando...
                  <small>Espere unos segundos..</small>
                </h6>
              </div>
            </div>
          }
        >
          <Route path="/dashboards" component={Dashboards} />
          <Route path="/settings" component={Settings} />
          <Route path="/welcome" component={Welcome} />
        </Suspense>

        <Route
          exact
          path="/historialalarmas"
          render={() => <Redirect to="/dashboards/histalarmas" />}
        />

        <Route
          exact
          path="/historialalarmas"
          render={() => <Redirect to="/dashboards/histalarmas" />}
        />

        {/* <Route
        exact
        // path="/"
        component={UserPages}/> */}
        <Route exact path="/" render={() => <Redirect to="/pages/login" />} />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default AppMain;
