export const SET_USER_NAME = "SESSION/USER_NAME";
export const SET_USER_EMAIL = "SESSION/USER_EMAIL";
export const SET_ROLE_ID = "SESSION/ROLE_ID";
export const SET_USER_ID = "SESSION/USER_ID";
export const SET_COMPANY_ID = "SESSION/COMPANY_ID";
export const SET_COMPANY_NAME = "SESSION/COMPANY_NAME";
export const SET_ROLE_CODE = "SESSION/ROLE_CODE";
export const SET_WELCOME = "SESSION/WELCOME";

export const setUserName = (userName) => ({
  type: SET_USER_NAME,
  userName,
});
export const setEmailUsuario = (emailUsuario) => ({
  type: SET_USER_EMAIL,
  emailUsuario,
});
export const setRoleId = (roleId) => ({
  type: SET_ROLE_ID,
  roleId,
});
export const setUserId = (userId) => ({
  type: SET_USER_ID,
  userId,
});
export const setCompanyId = (companyId) => ({
  type: SET_COMPANY_ID,
  companyId,
});
export const setCompanyName = (companyName) => ({
  type: SET_COMPANY_NAME,
  companyName,
});
export const setRoleCode = (roleCode) => ({
  type: SET_ROLE_CODE,
  roleCode,
});
export const setWelcome=(welcome)=>({
  type:SET_WELCOME,
  welcome
});
const initialState = {
  userId: null,
  userName: null,
  emailUsuario: null,
  roleId: null,
  roleCode: null,
  companyId: null,
  companyName: null,
  welcome:null,
};

export default function reducer(state = initialState, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        emailUsuario: action.emailUsuario,
      };
    case SET_ROLE_ID:
      return {
        ...state,
        roleId: action.roleId,
      };
    case SET_ROLE_CODE:
      return {
        ...state,
        roleCode: action.roleCode,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId,
      };
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.companyId,
      };
    case SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.companyName,
      };
    case SET_WELCOME:
      return {
        ...state,
        welcome:action.welcome
      }
  }
  return state;
}
