import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import { store, persistor } from "./config/configureStore";
import { Provider } from "react-redux";

const mystore = store;
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={mystore}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Component />
        </HashRouter>
      </PersistGate>
    </Provider>,
    rootElement
  );
};

try{
    const clearCache = async () => {
        await caches.delete("workbox-precache-https://iot.idealcontrolcloud.com/");
        await caches.delete("workbox-precache-https://iot.idealcontrolcloud.com/-temp");
    };

    //clearCache();
}catch(e){
    console.log(e)
}

renderApp(Main);

if (module.hot) {
    module.hot.accept("./Pages/Main", () => {
        const NextApp = require("./Pages/Main").default;
        renderApp(NextApp);
    });
}
serviceWorker.register();
